<template>
  <div class="dropdowns-menu__col dropdowns-menu__col-banner">
    <uc-link :to="setBannerLink(slide.props)">
      <uc-image :img="slide.value" :alt="`menu-banner-${slide.title}`" :height="`650`" folder="slider-image" />
    </uc-link>
  </div>
</template>
<script>
export default {
  name: 'AppNavigationBanner',
  props: {
    category: {
      type: String,
      default: ''
    },
    slide: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    setBannerLink(props) {
      const link = props.find((prop) => prop.key === 'link');

      return link ? link.value : '';
    }
  }
};
</script>
