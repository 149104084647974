<template>
  <li class="menu-wrapper__list-item" @click="closeMenu()">
    <uc-link
      class="menu-wrapper__list-item__link menu-wrapper__list-item__link-outlet"
      title="Outlet"
      aria-label="Outlet"
      :to="localePath({ name: 'outlet-page' })"
    >
      <div class="menu-wrapper__list-item__link-img">
        <img :src="`https://static.chicco.com.ua/assets/svg/menu/chicco/new_style2/outlet.svg`" alt="outlet" />
      </div>
      <span class="menu-wrapper__list-item__link-title">Outlet</span>
      <img
        class="menu-wrapper__list-item__link-arrow"
        src="https://static.chicco.com.ua/assets/svg/menu/menu-arrow.svg"
        alt="arrow"
      />
    </uc-link>
  </li>
</template>

<script>
export default {
  methods: {
    closeMenu() {
      this.$parent.closeMobileMenu();
    }
  }
};
</script>
