<template>
  <uc-modal v-model="dialogDisplay">
    <div class="product-add-size">
      <div class="add-size__title">{{ $t('Выберите размер') }}</div>
      <div class="add-size__wrap">
        <div
          v-for="(size, index) in sizes"
          :key="`nomenclature-size-${index}`"
          class="add-size__item"
          :class="{ disabled: !size.isAvailable }"
          @click="addToBasket(size.slug, count)"
        >
          {{ size.slug }}
        </div>
      </div>
    </div>
  </uc-modal>
</template>

<script>
import { AddToBasketMixin, ScreenWidthMixin } from 'u-mixins';
import { productModels } from 'u-models';
const ProductModel = productModels.product;

export default {
  mixins: [AddToBasketMixin, ScreenWidthMixin],
  data() {
    return {
      dialogDisplay: false,
      product: {},
      nomenclature: {},
      sizes: [],
      color: '',
      count: ''
    };
  },
  mounted() {
    this.$eventBus.$on('modals:add_size_to_basket:open', this.openModal);
  },
  beforeDestroy() {
    this.$eventBus.$off('modals:add_size_to_basket:open', this.openModal);
  },
  methods: {
    openModal(data) {
      this.product = data.product;
      this.nomenclature = data.nomenclature;
      this.sizes = data.sizes;
      this.color = data.color;
      this.count = data.count;
      this.dialogDisplay = true;
    },
    addToBasket(size, count = 1) {
      const productModel = new ProductModel(this.product);
      const nomenclature = productModel.getChosenNomenclature(this.color, size);
      this.addItemToBasket(nomenclature, count);
      this.dialogDisplay = false;
      this.isScreenTabletAndLess
        ? this.$eventBus.$emit('basket_add_notification:display', { ...nomenclature, count })
        : this.$eventBus.$emit('basket_modal:display');
    }
  }
};
</script>
<style lang="scss">
@import '~/assets/scss/modals/modal-add-size.scss';
</style>
