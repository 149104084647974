export default {
  data() {
    return {
      isHovered: false,
      timeoutHover: null
    };
  },
  mounted() {
    this.$eventBus.$on('nav:dropdown:hide', this.hideMenu);
  },
  beforeDestroy() {
    this.$eventBus.$off('nav:dropdown:hide', this.hideMenu);
  },
  methods: {
    mouseEnterHandler() {
      clearTimeout(this.timeoutHover);

      this.timeoutHover = setTimeout(() => {
        this.isHovered = true;
      }, 400);
    },
    mouseLeaveHandler() {
      if (this.isHovered) {
        this.timeoutHover = setTimeout(() => {
          this.isHovered = false;
        }, 450);
      } else {
        clearTimeout(this.timeoutHover);
      }
    },
    hideMenu() {
      if (this.isHovered) {
        this.isHovered = false;
      } else {
        clearTimeout(this.timeoutHover);
      }
    }
  }
};
