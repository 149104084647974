<template>
  <div>
    <noscript inline-template>
      <div class="noscript-default" data-nosnippet>
        <h3>{{ $t('noscriptDefaultTitle') }}</h3>
        <p>
          {{ $t('noscriptDefaultText') }}
        </p>
      </div>
    </noscript>
    <app-header-banner />
    <app-header />
    <nuxt />
    <app-footer />
    <app-backdrop />
    <auth-modal />
    <client-only>
      <notifications
        group="default"
        :duration="12000"
        :width="500"
        animation-name="v-fade-left"
        position="top center"
        class="notifications"
      >
        <template slot="body" slot-scope="props">
          <div class="vue-notification" :class="`vue-notification-${props.item.type}`">
            <div class="vue-notification-icon">
              <span class="icon" :class="`icon-${props.item.type}`"></span>
            </div>
            <div class="vue-notification-content">
              <div class="vue-notification-title">{{ $t(`${props.item.title}`) }}</div>
              <div v-if="props.item.text" class="vue-notification-text">{{ $t(`${props.item.text}`) }}</div>
            </div>
            <div class="vue-notification-close" @click="props.close">
              <p :class="`vue-notification-close-${props.item.type}`">{{ $t('Закрыть') }}</p>
            </div>
          </div>
        </template>
      </notifications>

      <modal-basket class="basket-modal-wrapper" />
      <add-size-to-basket-modal />
      <gifts-modal />
      <subscribe-thanks-modal />
      <subscription-modal v-if="false && innerNavigated" />
      <user-exists-modal />
      <add-to-wish-list />
      <basket-notification />
      <birthday-bonus-modal />
    </client-only>
  </div>
</template>

<script>
import AppHeader from '~/src/components/general/AppHeader.vue';
import AppHeaderBanner from '~/src/components/general/AppHeaderBanner.vue';
import AppFooter from '~/src/components/general/AppFooter.vue';
import AppBackdrop from '~/src/components/general/AppBackdrop.vue';
import ModalBasket from '~/src/components/general/modals/ModalBasket.vue';
import AddSizeToBasketModal from '~/src/components/general/productItem/modal/AddSizeToBasketModal.vue';
import GiftsModal from '~/src/components/basket/modal/GiftsModal.vue';
import SubscribeThanksModal from '~/src/components/subscription/SubscribeThanksModal.vue';
import SubscriptionModal from '~/src/components/subscription/SubscriptionModal.vue';
import UserExistsModal from '~/src/components/subscription/UserExistsModal.vue';
import { AuthModal } from 'u-auth';
import metaRu from '~/src/meta/ru/MainMeta.json';
import metaUa from '~/src/meta/ua/MainMeta.json';
import SearchRequestMicroMarkup from '~/src/micro-markup/SearchRequest.json';
import OrganizationMicroMarkup from '~/src/micro-markup/Organization.json';
import AddToWishList from 'u-wishlist/lib/components/modal/catalog/AddToWishList.vue';
import BasketNotification from '~/src/components/basket/BasketNotification.vue';
import BirthdayBonusModal from '~/src/components/bonus/BirthdayBonusModal.vue';
import rulesExtender from 'u-utils/module/rules.js';

export default {
  components: {
    AppHeader,
    AppHeaderBanner,
    AppFooter,
    AppBackdrop,
    AuthModal,
    ModalBasket,
    AddSizeToBasketModal,
    GiftsModal,
    SubscribeThanksModal,
    SubscriptionModal,
    UserExistsModal,
    AddToWishList,
    BasketNotification,
    BirthdayBonusModal
  },
  middleware: 'DataLayerPath',
  data() {
    return {
      isNoScroll: false,
      innerNavigated: false
    };
  },
  head() {
    const meta = this.$metaService.getImportedMetaByLang(metaRu, metaUa, this.$i18n.locale);
    let isIndexed = !Object.keys(this.$route.query).length;

    if (
      this.$route.name === `order-page___${this.$i18n.locale}` ||
      this.$route.name === `thanks-order-page___${this.$i18n.locale}`
    ) {
      isIndexed = false;
    }

    const actualMeta = this.$metaService.getMeta(
      `${process.env.PROJECT_BASE_URL}${this.$route.path}`,
      (meta && meta.title) || '',
      (meta && meta.description) || '',
      (meta && meta.image) || '',
      (meta && meta.keywords) || '',
      null,
      null,
      null,
      isIndexed
    );
    const i18nSeo = this.i18nSeo();
    return {
      htmlAttrs: i18nSeo.htmlAttrs,
      bodyAttrs: {
        class: this.isNoScroll ? 'no-scroll' : ''
      },
      ...actualMeta,
      link: [...i18nSeo.link, ...meta.link],
      script: [
        {
          json: SearchRequestMicroMarkup[this.$i18n.locale],
          type: 'application/ld+json'
        },
        {
          json: OrganizationMicroMarkup[this.$i18n.locale],
          type: 'application/ld+json'
        }
      ]
    };
  },
  mounted() {
    this.navigateWatcher = this.$watch('$route.path', this.routePathHandler);
    this.$eventBus.$on('general:body:hold-scroll', this.setIsNoScroll);
    rulesExtender(this);
  },
  beforeDestroy() {
    this.$eventBus.$off('general:body:hold-scroll', this.setIsNoScroll);
  },
  methods: {
    setIsNoScroll(bool) {
      if (typeof bool === 'boolean') {
        this.isNoScroll = bool;
      }
    },
    routePathHandler() {
      this.innerNavigated = true;
      this.navigateWatcher();
    }
  }
};
</script>

<style lang="scss" src="~/assets/scss/spacing-mixin.scss" />
