<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__wrapper">
        <div class="footer__info">
          <logo />
          <div class="contacts">
            <div class="contact__phone"><a href="tel:0443230912">(044) 323-09-12</a></div>
            <div class="contacts__schedule">
              <div class="schedule-time">{{ $t('пн-вс: с') }} 09:00 {{ $t('до') }} 20:00</div>
            </div>
            <div class="contacts__socials">
              <a
                ref="nofollow"
                target="_blank"
                href="https://www.facebook.com/chiccoukr"
                class="contacts__socials-link"
              >
                <img src="https://static.chicco.com.ua/chicco-assets/svg/footer/facebook-icon.svg" alt="facebook" />
              </a>
              <a
                ref="nofollow"
                target="_blank"
                href="https://www.instagram.com/chiccoukraine/"
                class="contacts__socials-link"
              >
                <img src="https://static.chicco.com.ua/chicco-assets/svg/footer/instagram-icon.svg" alt="instagram" />
              </a>
              <a
                ref="nofollow"
                target="_blank"
                href="https://www.youtube.com/user/chiccoukr"
                class="contacts__socials-link"
              >
                <img src="https://static.chicco.com.ua/chicco-assets/svg/footer/youtube-icon.svg" alt="youtube" />
              </a>
              <a ref="nofollow" target="_blank" href="https://telegram.me/Chiccoukr_bot" class="contacts__socials-link">
                <img src="https://static.chicco.com.ua/chicco-assets/svg/footer/telegram-icon.svg" alt="telegram" />
              </a>
            </div>
          </div>
        </div>
        <div class="footer__menu">
          <div class="menu__list">
            <div class="list__title">{{ $t('Магазины') }}</div>
            <div class="list__links">
              <uc-link :to="localePath({ name: 'info-page', params: { slug: 'shops' } })" class="list__link">{{
                $t('Магазины на карте')
              }}</uc-link>
              <uc-link :to="localePath({ name: 'info-page', params: { slug: 'bonus-rules' } })" class="list__link"
                >{{ $t('Программа МА Кешбэк') }}
              </uc-link>
              <uc-link :to="localePath({ name: 'promotion-page' })" class="list__link">{{
                $t('Акции и распродажи')
              }}</uc-link>
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'gift-sertificate' } })"
                class="list__link"
                >{{ $t('Подарочные сертификаты') }}</uc-link
              >
              <a ref="nofollow" target="_blank" href="https://ma.com.ua" class="list__link">{{
                $t('MA.COM.UA – генеральный партнер')
              }}</a>
            </div>
          </div>
          <div class="menu__list">
            <div class="list__title">{{ $t('Покупателю') }}</div>
            <div class="list__links">
              <uc-link :to="localePath({ name: 'info-page', params: { slug: 'delivery' } })" class="list__link">{{
                $t('Доставка и оплата')
              }}</uc-link>
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'service-warranty' } })"
                class="list__link"
                >{{ $t('Гарантия и сервис') }}</uc-link
              >
              <uc-link :to="localePath({ name: 'info-page', params: { slug: 'contact-us' } })" class="list__link">{{
                $t('Обратная связь')
              }}</uc-link>
              <uc-link :to="localePath({ name: 'info-page', params: { slug: 'faq' } })" class="list__link">{{
                $t('Вопросы и ответы')
              }}</uc-link>
              <uc-link :to="localePath({ name: 'pakunok-malyuka-page' })" class="list__link">{{
                $t('Пакунок малюка')
              }}</uc-link>
              <uc-link v-if="false" :to="localePath({ name: '' })" class="list__link">{{ $t('Вакансии') }}</uc-link>
            </div>
          </div>
          <div class="menu__list">
            <div class="list__title">{{ $t('О компании') }}</div>
            <div class="list__links">
              <uc-link :to="localePath({ name: 'info-page', params: { slug: 'about-shop' } })" class="list__link">{{
                $t('Про Chicco')
              }}</uc-link>
              <uc-link :to="localePath({ name: 'social-project' })" class="list__link">{{
                $t('Социальные проекты')
              }}</uc-link>
              <uc-link :to="localePath({ name: 'news-page' })" class="list__link">{{ $t('Новости компании') }}</uc-link>
              <uc-link :to="localePath({ name: 'blog-page' })" class="list__link">{{ $t('Это интересно!') }}</uc-link>
              <uc-link :to="localePath({ name: 'info-page', params: { slug: 'purchase-terms' } })" class="list__link">{{
                $t('Условия покупки')
              }}</uc-link>
            </div>
          </div>
        </div>
      </div>
      <div class="footer__copyright">
        <div>{{ $t('Официальный импортер в Украине:') }}</div>
        <div>{{ $t('ООО "Миллениум Трейд", 03680, г. Киев, ул. Физкультуры, 28') }}</div>
      </div>
      <div class="version">
        <div>Branch {{ branch }}, version {{ version }} at {{ buildTime }}</div>
        <div>ENV {{ env }}</div>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from '~/src/components/general/Logo.vue';

export default {
  components: {
    Logo
  },
  data() {
    return {
      version: process.env.version,
      branch: process.env.branch,
      buildTime: process.env.buildTime,
      env: process.env.NODE_ENV
    };
  }
};
</script>

<style lang="scss">
@import '~/assets/scss/footer/footer.scss';
</style>
