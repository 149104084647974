<template>
  <div
    class="backdrop"
    :style="`background-color: ${background};`"
    :class="{ active: visibility }"
    @click="clickHandler"
  />
</template>

<script>
const defaultColor = 'rgba(255, 255, 255, 0.9)';

export default {
  data() {
    return {
      visibility: false,
      background: defaultColor
    };
  },
  mounted() {
    this.$eventBus.$on('app-backdrop:display', this.display);
    this.$eventBus.$on('app-backdrop:hide', this.hide);
  },
  beforeDestroy() {
    this.$eventBus.$off('app-backdrop:display', this.display);
    this.$eventBus.$off('app-backdrop:hide', this.hide);
  },
  methods: {
    display(color) {
      if (color) {
        this.background = color;
      }

      this.visibility = true;
    },
    hide() {
      this.background = defaultColor;
      this.visibility = false;
    },
    clickHandler() {
      this.$eventBus.$emit('app-backdrop:click');
    }
  }
};
</script>

<style lang="scss">
.backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  z-index: 5;

  &.active {
    display: block;
    transition: 0.3s ease-in-out;
  }
}
</style>
