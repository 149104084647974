<template>
  <uc-modal v-model="isModalVisible">
    <div class="subscription-modal subscription-thnx-background">
      <uc-render-html
        :html="$t('Спасибо за подписку. Ожидайте письмо с подтверждением на почте')"
        class="modal-text__thnx"
      />
    </div>
  </uc-modal>
</template>

<script>
export default {
  name: 'SubscribeThanksModal',
  data() {
    return {
      isModalVisible: false
    };
  },
  watch: {
    isModalVisible(isOpen) {
      this.$eventBus.$emit('general:body:hold-scroll', isOpen);
    }
  },
  mounted() {
    this.$eventBus.$on('subscribe:modal:show', this.show);
  },
  beforeDestroy() {
    this.$eventBus.$emit('general:body:hold-scroll', false);
    this.$eventBus.$off('subscribe:modal:show', this.show);
  },
  methods: {
    close() {
      this.isModalVisible = false;
    },
    show() {
      this.isModalVisible = true;
    }
  }
};
</script>

<style lang="scss">
@import '~/assets/scss/modals/modal-subscription.scss';
</style>
